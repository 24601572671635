<template lang="pug">
  div.container-fluid
    div.row.justify-content-between(:class="user.is_deleted? 'deleted':''")
      h5 User Detail
      template
        h5.text-danger.deleted-h5.mb-0 This User is deleted.
        div.col-12.col-lg-5
          table.table.table-sm.caption-top
            caption Basic
            tbody
              tr
                th ID
                td {{ user.id }}
              tr
                th Name
                td {{ user.name }}
              tr
                th Email
                td {{ user.email }}
                  <svg v-if="user.email_verified" class="feather feather-check-circle ms-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-e0b47cf6=""><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
              tr
                th Stripe
                td {{ user.stripe_customer_id }}
              tr
                th Stripe Card
                td <svg v-if="user.stripe_pm_id" class="feather feather-check-circle ms-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-e0b47cf6=""><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
              tr
                th Twitter
                td {{ user.twitter }}
              tr
                th Updated
                td {{ user.updated_at | moment }}
              tr
                th Created
                td {{ user.created_at | moment }}
              tr
                th Emails
                td
                  p.mb-0(v-for="mail in user.mail_list") {{ mail }}
              tr
                th LINE
                td
                  p.mb-0 {{ user.line }}
              tr
                th Discord
                td
                  p.mb-0 {{ user.discord_webhook }}
        div.col-6.col-lg-2
          table.table.table-sm.usage-table.caption-top
            caption Usage
            tbody
              tr
                th 1min
                td {{ user._1min }}
              tr
                th 10min
                td {{ user._10min }}
              tr
                th 60min
                td {{ user._60min }}
              tr
                th SS
                td {{ user.ss }}
              tr
                th Mails
                td {{ user.mails }}
              tr
                th RandomIP
                td {{ user.random_ip }}
              tr
                th LINE
                td {{ user.line_? "1":"-" }}
              tr
                th TD・EDI
                td
                  p.mb-0 {{ user.tdedi.length }}

        div.col-6.col-lg-3
          table.table.table-sm.locale-table.caption-top
            caption Locale
            tbody
              tr
                th Language
                td {{ user.lang }}
              tr
                th Timezone
                td {{ user.timezone }}
              tr
                th Currency
                td {{ user.currency }}
          div
            label Memo
            textarea.form-control(:value="JSON.stringify(user.memo, null, 2)", rows="5", readonly)
    div.row.justify-content-between.mt-3
      p.mb-0 {{ user.tdedi }}
    Subscriptions.mt-3(:user_id="user_id")
    div.row.justify-content-between.mt-3
      div.col
        router-link(:to="{ path:'/targets2u', query:{ user_id:user_id } }")
          h5 TargetSitesV2
    div.row.justify-content-between.mt-3
      div.col
        router-link(:to="{ path:'/attempttargets2', query:{ user:user_id } }")
          h5 AttemptTargetV2


</template>

<script>
import feather from 'feather-icons';
import moment from 'moment';
import Subscriptions from './Subscriptions.vue';

export default {
  name:"User",
  components:{
    Subscriptions
  },
  data (){
    return {
      user_id:null,
      user:{ id:"", name:"", email:"", plan:"", ex:"", _1min:"", _10min:"", _60min:"", ss:"", mails:"", line:"", twitter:"", tdedi:[] },
    }
  },
  computed :{
  },
  created (){
    this.user_id = Number(this.$route.query.id);
  },
  mounted (){
    feather.replace();
    this.get(this.user_id);
  },
  methods: {
    get (url){
      this.axios.get("/api/v2/user?id="+this.user_id)
      .then((response) => {
        this.user = response.data;
      })
      .catch((e)=>{
        console.log(e);
        console.log(e.response);
        this.$swal("","","error");
      });
    },
  },
  filters: {
    moment (timestamp) {
      if (timestamp)
        return moment(new Date(timestamp*1000)).format('YYYY/MM/DD HH:mm');
      else
        return "";
    }
  }
}
</script>

<style lang="scss" scoped>
.usage-table {
  td {
    vertical-align: middle;
    text-align: center;
  }
}
.locale-table {
  td {
    vertical-align: middle;
    text-align: center;
  }
}

.deleted-h5 {
  display: none;
}

.deleted {
  td {
    text-decoration: line-through;
  }
  .deleted-h5 {
    display: block;
  }
}

.feather {
  width: 18px;
  height: 18px;
  vertical-align: text-bottom;
  margin-top: 1px;
}
</style>